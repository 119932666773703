// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-travel-services-js": () => import("./../../../src/pages/travel-services.js" /* webpackChunkName: "component---src-pages-travel-services-js" */),
  "component---src-templates-case-studies-js": () => import("./../../../src/templates/case-studies.js" /* webpackChunkName: "component---src-templates-case-studies-js" */),
  "component---src-templates-case-study-js": () => import("./../../../src/templates/case-study.js" /* webpackChunkName: "component---src-templates-case-study-js" */),
  "component---src-templates-video-js": () => import("./../../../src/templates/video.js" /* webpackChunkName: "component---src-templates-video-js" */),
  "component---src-templates-videos-js": () => import("./../../../src/templates/videos.js" /* webpackChunkName: "component---src-templates-videos-js" */),
  "component---src-templates-workshop-js": () => import("./../../../src/templates/workshop.js" /* webpackChunkName: "component---src-templates-workshop-js" */),
  "component---src-templates-workshops-js": () => import("./../../../src/templates/workshops.js" /* webpackChunkName: "component---src-templates-workshops-js" */)
}

