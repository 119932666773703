module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Cometari Website","short_name":"starter","start_url":"/","background_color":"#7389ff","theme_color":"#7389ff","display":"minimal-ui","icon":"src/images/logos/cometari/sygnet.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"70efb237c2b7d4bae5988b0845e280d9"},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#7389ff","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-33232767-2"]},
    }]
